import React from 'react'

const Construction = () => {
  return (
    <div>
    <b>Under Construction</b>
      
    </div>
  )
}

export default Construction
